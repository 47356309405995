import React from 'react';

const OtherSkills = () => {
    return (
        <div className="otherSkills">
            <h3>Other Skills</h3>
            <div className="list">
                <ul>
                    <li><i class="fas fa-check-square"></i>Fluent English</li>
                    <li><i class="fas fa-check-square"></i>Git</li>
                    <li><i class="fas fa-check-square"></i>Agile Methodology</li>
                    <li><i class="fas fa-check-square"></i>SQL</li>
                    <li><i class="fas fa-check-square"></i>NoSQL</li>
                    <li><i class="fas fa-check-square"></i>UML</li>
                    <li><i class="fas fa-check-square"></i>Wireframing</li>
                    <li><i class="fas fa-check-square"></i>Kotlin</li>
                    <li><i class="fas fa-check-square"></i>Project Management</li>
                    <li><i class="fas fa-check-square"></i>Backend Conception</li>
                    <li><i class="fas fa-check-square"></i>Reactjs</li>
                    <li><i class="fas fa-check-square"></i>Software Architecture</li>

                    

                </ul>
            </div>
        </div>
    );
};

export default OtherSkills;