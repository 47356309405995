import React from 'react';

const Experiences = () => {
    return (
        <div className="experiences">
            <h3>Experiences</h3>
            <div className="exp1">
                <h4>Java & Angular Developer</h4>
                <h5><span>CGI Bordeaux</span> | Limited-term Contract. Apr.2023 - Sept.2023</h5>
                <p>After my apprenticeship, CGI Bordeaux decided to hire me. I was part of the same project, that gave me the opportunity to master :<br />
                        -Spring-Boot backend developement <br />
                        -Security in Angular <br />
                        -Software Architecture</p>
            </div>
            <div className="exp2">
                <h4>Apprentice Developer</h4>
                <h5><span>CGI Bordeaux</span> | Apprenticeship. Apr 2022 - Apr 2023</h5>
                <p>During my apprenticeship within CGI Bordeaux, I was part during the first month of developement of a new project. During those month, here is what I have learned :<br />
                    -Angular web language<br />
                    -The Agile method<br />
                    -Security in Spring Boot Application
                </p>
            </div>
            <div className="exp3">
                <h4>Mobile app Designer and Developer</h4>
                <h5><span>Alexandre ODA</span> | Internship. Oct.2021 - Nov.2021</h5>
                <p>Design and Development of an Android application based on data protection against IMSI Catchers. My contributions were : <br />
                        -UML <br />
                        -Wireframing <br />
                        -Developping a Java Android application</p>
            </div>
        </div>
    );
};

export default Experiences;