export const portfolioData = 
[
    {
        id:2,
        name: 'Bee Message',
        languages:["kotlin","all"],
        languagesIcons:["fab fa-android"],
        source:"https://github.com/milaneuh/bee-message",
        info: "'This app waswas made in Kotlin and used Firebase Firestore as a database and Firebase Cloud Messaging for the messaging solution.",
        picture:'bee-message.png'
    },
    {
        id:3, 
        name:'Chat App',
        languages:["php","css","javascript","all"],
        languagesIcons:["fab fa-php","fab fa-css3-alt","fab fa-js"],
        source:"https://github.com/milaneuh/Web-chat-app",
        info:"This Personnal Project is an instant messaging web application using Php to communicate with my MySQL database. I made this project to excercise myself with MySQL and Php.",
        picture:'chat-app.png'
    },
    {
        id:4,
        name:"My Portfolio",
        languages:["react","css","all"],
        languagesIcons:["fab fa-react","fab fa-css3-alt"],
        source:'https://github.com/milaneuh/react-portofolio-milan',
        info:"You are currently on this website! This website was made with React.JS and SCSS. I tried to use as much SCSS and React as possible to show my skills.",
        picture:'my-portfolio.PNG'
    },
    {
        id:6,
        name:"JWT Secure auth",
        languages:["java","all"],
        languagesIcons:["fab fa-java"],
        source:"https://github.com/milaneuh/Secure_jwt_auth",
        info:"Study project where I started learning JWT Authentication and Authorization in Spring Boot RestAPI",
        picture:"gdm-rest-api.png"
    },
    {
        id:7,
        name:"GDMG RestAPI",
        languages:["java","all"],
        languagesIcons:["fab fa-java"],
        source:"https://github.com/milaneuh/GDMG-RestApi",
        info:"End of study project. It is a backend for a hotel management website",
        picture:"jwt-secure-auth.PNG"
    }
]