import Home from './pages/Home';
import Skills from './pages/Skills';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Notfound from './pages/Notfound';
import {Routes,Route} from 'react-router-dom';
function App() {
  return (
    <>
   <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/skills"  element={<Skills />}/>
          <Route path="/portfolio"  element={<Portfolio />} />
          <Route path="/contact"  element={<Contact />} />
          <Route  element={<Notfound />}/>
    </Routes>
    </>
  );
}

export default App;
